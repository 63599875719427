import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap"
import { BiWifi, BiWifiOff } from "react-icons/bi"
const portfolio = () => {
  return (
    <Layout>
      <Seo
        title="We create world-class digital products, web design, and branding"
        description="We bring custom user experiences and website solutions to our amazing clients, ranging from local startups to large corporations and non-profit organizations."
        keyword="Our Portfolio"
      />
      <Container fluid className="text-center">
        <br />
        <br />
        <br />
        <h1 className="text3d fs-3 mb-5">PORTFOLIO</h1>
        <Container>
        <br />
        <h2
            className="text-center bg-textlight"
            data-aos="flip-left"
          >
            Web Design
          </h2>
          <br />
        <Row sm={1} md={2} lg={2} className="g-4 text-start fs-6">
          <Col>
            <Card style={{ cursor: "default" }}>
              <div>
                <StaticImage
                  alt="Portfolio poster"
                  src="../images/portfolio/thesoftcode_web.png"
                  placeholder="tracedSVG"
                  layout="fullWidth"
                  className="ms-auto my-auto"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <Card.Body className="p-0">
                <Card.Title className="text-center mt-3">
                  The SoftCode
                </Card.Title>
                <hr className="mb-0" />
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    Work Type:&nbsp;Custom Web Design
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Technology:&nbsp;
                    <a
                      href="https://www.telerik.com/blogs/all-things-react"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ReactJs
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Brands use these technology: <br />
                    <a
                      href="https://www.netflix.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Netflix
                    </a>
                    ,&nbsp;
                    <a
                      href="https://www.nytimes.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      New York Times
                    </a>
                    ,&nbsp;
                    <a
                      href="https://www.tesla.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tesla
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Hosting:&nbsp;
                    <a
                      href="https://en.wikipedia.org/wiki/Cloud_storage"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cloud
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Security:&nbsp;
                    <a
                      href="https://www.cloudflare.com/learning/ssl/what-is-ssl/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SSL Encryption
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Status: <BiWifi className="text-success mb-1" />
                    &nbsp;Live
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
              <Card.Footer className="text-center">
                <small className="text-muted">
                  <a
                    href="https://thesoftcode.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    thesoftcode.com
                  </a>
                </small>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card style={{ cursor: "default" }}>
              <div>
                <StaticImage
                  alt="Rockey Freights Web Layout"
                  src={"../images/portfolio/rockeyfreights_web.png"}
                  placeholder={"tracedSVG"}
                  layout={"fullWidth"}
                  className="ms-auto my-auto"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <Card.Body className="p-0">
                <Card.Title className="text-center mt-3">
                  Rockey Freights
                </Card.Title>
                <hr className="mb-0" />
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    Work Type:&nbsp; Custom Web Design
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Technology:&nbsp;
                    <a
                      href="https://www.gatsbyjs.com/why-gatsby/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gatsby
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Brands use these technology: <br />
                    <a
                      href="https://www.snapchat.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Snapchat
                    </a>
                    ,&nbsp;
                    <a
                      href="https://tinder.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tinder
                    </a>
                    ,&nbsp;
                    <a
                      href="https://ca.braun.com/en-ca"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Braun
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Hosting:&nbsp;
                    <a
                      href="https://en.wikipedia.org/wiki/Cloud_storage"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cloud
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Security:&nbsp;
                    <a
                      href="https://www.cloudflare.com/learning/ssl/what-is-ssl/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SSL Encryption
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Status: <BiWifi className="text-success mb-1" />
                    &nbsp;Live
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
              <Card.Footer className="text-center">
                <small className="text-muted">
                  <a
                    href="https://rockyfreights.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    rockyfreights.com
                  </a>
                </small>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card style={{ cursor: "default" }}>
              <div>
                <StaticImage
                  alt="True Church Web Design"
                  src={"../images/portfolio/truechurch_web.png"}
                  placeholder={"tracedSVG"}
                  layout={"fullWidth"}
                  className="ms-auto my-auto"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <Card.Body className="p-0">
                <Card.Title className="text-center mt-3">
                  True Church
                </Card.Title>
                <hr className="mb-0" />
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    Work Type:&nbsp; Custom Web Design
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Technology:&nbsp;
                    <a
                      href="https://en.wikipedia.org/wiki/HTML5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      HTML5
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CSS3
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://en.wikipedia.org/wiki/JQuery"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JQuery
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Brands use these technology: <br />
                    <a
                      href="https://www.amazon.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Amazon
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://www.delltechnologies.com/en-us/index.htm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Dell
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://www.microsoft.com/en-us/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Microsoft
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Hosting:&nbsp;
                    <a
                      href="https://en.wikipedia.org/wiki/Cloud_storage"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cloud
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Security:&nbsp;
                    <a
                      href="https://www.cloudflare.com/learning/ssl/what-is-ssl/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SSL Encryption
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Status: <BiWifi className="text-success mb-1" />
                    &nbsp;Live
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
              <Card.Footer className="text-center">
                <small className="text-muted">
                  <a
                    href="https://ourtruechurch.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ourtruechurch.org
                  </a>
                </small>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card style={{ cursor: "default" }}>
              <div>
                <StaticImage
                  alt="Mahavir Chemicals Demo"
                  src={"../images/portfolio/mahavirchems_web.png"}
                  placeholder={"tracedSVG"}
                  layout={"fullWidth"}
                  className="ms-auto my-auto"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <Card.Body className="p-0">
                <Card.Title className="text-center mt-3">
                  Mahavir Chemicals
                </Card.Title>
                <hr className="mb-0" />
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    Work Type:&nbsp; Custom Web Design(prototype)
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Technology:&nbsp; 
                    <a
                      href="https://en.wikipedia.org/wiki/HTML5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      HTML5
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CSS3
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://en.wikipedia.org/wiki/JQuery"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JQuery
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://mdbootstrap.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MDBootstrap
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Brands use these technology: <br />
                    <a
                      href="https://www.spotify.com/us/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Spotify
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://www.udemy.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Udemy
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://www.postman.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Postman
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Hosting:&nbsp;
                    <a
                      href="https://en.wikipedia.org/wiki/Cloud_storage"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Not Hosted
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Security:&nbsp;
                    <a
                      href="https://www.cloudflare.com/learning/ssl/what-is-ssl/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SSL Encryption
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Status: <BiWifiOff className="text-danger mb-1" />
                    &nbsp;Prototype
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
              <Card.Footer className="text-center">
                <small className="text-muted">Future Product</small>
              </Card.Footer>
            </Card>
          </Col>
          </Row>
          <br />
          <h2
            className="text-center bg-textlight"
            data-aos="flip-left"
          >
            E-Commerce
          </h2>
          <br />
          <Row sm={1} md={2} lg={2} className="g-4 text-start fs-6">
          <Col>
            <Card style={{ cursor: "default" }}>
              <div>
                <StaticImage
                  alt="Viyusoft e-commerce demo"
                  src={"../images/portfolio/viyusoft_ecommerce.png"}
                  placeholder={"tracedSVG"}
                  layout={"fullWidth"}
                  className="ms-auto my-auto"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <Card.Body className="p-0">
                <Card.Title className="text-center mt-3">Viyusoft</Card.Title>
                <hr className="mb-0" />
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    Work Type:&nbsp; e-commerce Demo
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Technology: <br />
                    <a
                      href="https://en.wikipedia.org/wiki/HTML5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      HTML5
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CSS3
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://en.wikipedia.org/wiki/JQuery"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JQuery
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://en.wikipedia.org/wiki/PHP"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PHP
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://laravel.com/docs/8.x#why-laravel"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Laravel
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Brands use these technology: <br />
                    <a
                      href="https://9gag.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      9GAG
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://www.pfizer.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pfizer
                    </a>
                    &nbsp;,&nbsp;
                    <a
                      href="https://www.bbc.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BBC
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Hosting:&nbsp;
                    <a
                      href="https://en.wikipedia.org/wiki/Cloud_storage"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cloud
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Security:&nbsp;
                    <a
                      href="https://www.cloudflare.com/learning/ssl/what-is-ssl/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SSL Encryption
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Status: <BiWifi className="text-success mb-1" />
                    &nbsp;Live (Demo)
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
              <Card.Footer className="text-center">
                <small className="text-muted">
                  <a
                    href="https://viyusoft.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    viyusoft.com
                  </a>
                </small>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <br />
        <h2
            className="text-center bg-textlight"
            data-aos="flip-left"
          >
            Logos
          </h2>
          <Row className="w-50 ms-auto me-auto">
            <Col>
            <Card className="ms-auto me-auto mt-4" style={{ cursor: "default" }}>
              <div>
                <StaticImage
                  alt="Rockey Freights Logo Design"
                  src={"../images/portfolio/RFS_logo.png"}
                  placeholder={"tracedSVG"}
                  layout={"fullWidth"}
                  className="ms-auto my-auto"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <Card.Body className="p-0">
                <Card.Title className="text-center mt-3">Rockey Freights</Card.Title>
                <hr className="mb-0" />
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    Work Type:&nbsp; Logo Design
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
              <Card.Footer className="text-center">
                <small className="text-muted">
                  <a
                    href="https://rockyfreights.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    rockyfreights.com
                  </a>
                </small>
              </Card.Footer>
            </Card>
            </Col>
            <Col>
            <Card className="ms-auto me-auto mt-4" style={{ cursor: "default" }}>
              <div>
                <StaticImage
                  alt="Mahavir Chemicals Logo Design"
                  src={"../images/portfolio/Mahavir4.png"}
                  placeholder={"tracedSVG"}
                  layout={"fullWidth"}
                  className="ms-auto my-auto"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <Card.Body className="p-0">
                <Card.Title className="text-center mt-3">Mahavir Chemicals</Card.Title>
                <hr className="mb-0" />
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    Work Type:&nbsp; Logo Design
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
              <Card.Footer className="text-center">
                <small className="text-muted">

                    mahavir Chemicals
                </small>
              </Card.Footer>
            </Card>
            </Col>
            </Row>
        <br />
        <h2
            className="text-center bg-textlight"
            data-aos="flip-left"
          >
            Business Cards
          </h2>
          <Row xs={12} sm={6} md={3}>
            <Col className="ms-auto me-auto">
            <Card className="ms-auto me-auto mt-4" style={{ cursor: "default" }}>
              <div>
                <StaticImage
                  alt="Rockey Freights Business Card"
                  src={"../images/portfolio/rfs_business_card.png"}
                  placeholder={"tracedSVG"}
                  layout={"constrained"}
                  width={514}
                  className="ms-auto my-auto"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <Card.Body className="p-0">
                <Card.Title className="text-center mt-3">Rockey Freights</Card.Title>
                <hr className="mb-0" />
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    Work Type:&nbsp; Business Card Design
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
              <Card.Footer className="text-center">
                <small className="text-muted">
                  <a
                    href="https://rockyfreights.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    rockyfreights.com
                  </a>
                </small>
              </Card.Footer>
            </Card>
            </Col>
            </Row>
        <br />
        <h2
            className="text-center bg-textlight"
            data-aos="flip-left"
          >
            Letterheads
          </h2>
          <Row xs={12} sm={6} md={3}>
            <Col className="ms-auto me-auto">
            <Card className="mt-4" style={{ cursor: "default" }}>
              <div>
                <StaticImage
                  alt="Rockey Freights Letterhead"
                  src={"../images/portfolio/RFS_Letterhead.png"}
                  placeholder={"tracedSVG"}
                  layout={"constrained"}
                  width={514}
                  className="ms-auto my-auto"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <Card.Body className="p-0">
                <Card.Title className="text-center mt-3">Rockey Freights</Card.Title>
                <hr className="mb-0" />
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    Work Type:&nbsp; Letterhead Design
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
              <Card.Footer className="text-center">
                <small className="text-muted">
                  <a
                    href="https://rockyfreights.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    rockyfreights.com
                  </a>
                </small>
              </Card.Footer>
            </Card>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
      </Container>
    </Layout>
  )
}

export default portfolio
